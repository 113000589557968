import { GetIncidentsRequest } from '../app/model/getIncidentsRequest';

const queryKeys = {
  monitor: (id: string) => ['monitors', id],
  organizationMonitors: (organizationId: string) => ['monitors', 'organization', organizationId],
  statusPageIncidents: (statusPageId: string) => ['statusPage', statusPageId, 'incidents'],
  statusPageIncident: (statusPageIncidentId: string) => ['statusPageIncident', statusPageIncidentId],
  incident: (id: string) => ['incident', id],
  incidents: (request?: GetIncidentsRequest) => (request ? ['incidents', request] : ['incidents']),
  organizationMonitorStatistics: (organizationId: string) => ['monitor-statistics', 'organization', organizationId],
  monitorTableMenu: (name: string) => ['monitor-table-menu', 'filters', name],
};

export default queryKeys;
