import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal
} from '@chakra-ui/react';
import { AiOutlineFilter } from 'react-icons/ai';
import React, { useEffect, useState } from 'react';
import {
  useMonitorTableMenuFiltersMutation,
  useMonitorTableMenuFiltersQuery
} from '../../query/monitorTableMenuQueries';
import {
  MonitorTransport,
} from '@um/uptime-monitoring-shared';

const MonitorTableMenu = () => {
  const { data } = useMonitorTableMenuFiltersQuery('');
  const { mutate } = useMonitorTableMenuFiltersMutation('');
  const [ monitorTypeChecks, setMonitorTypeChecks ] = useState([]);

  useEffect(() => {
    addMonitorChecks();
  }, [data]);

  const addMonitorChecks = () => {
    let monitorChecksList = [];
    const keys = Object.values(MonitorTransport)

    keys.forEach((transport) => {
      if((Object(data).hasOwnProperty(transport) && data[transport]) || !Object(data).hasOwnProperty(transport)){
        monitorChecksList.push(transport);
      }
    })
    setMonitorTypeChecks([...monitorChecksList]);
  }
  
  const onOptionClick = (transport) => {
    mutate(
      { filters: 
        { ...(data || {}), 
          [transport]: Object(data).hasOwnProperty(transport) ? !data[transport] : true 
        } 
      }
      )
  }
  
  return (<Menu closeOnSelect={false}>
    <MenuButton
      as={IconButton}
      variant="ghost"
      icon={<Icon as={AiOutlineFilter} boxSize={6} color="gray.400"/>}
    >
      Open menu
    </MenuButton>
    <Portal>
      <MenuList>
        <MenuOptionGroup title="Monitor Type" type="checkbox" value={monitorTypeChecks}>
          {
            Object.values(MonitorTransport).map((transport) => 
              <MenuItemOption 
                value={transport} 
                onClick={() => onOptionClick(transport)}>
                {transport.replaceAll("_", " ")}
              </MenuItemOption>
            )
          }
        </MenuOptionGroup>
        <MenuOptionGroup title="Columns" type="checkbox" value={[ !!data?.responseTime && "responseTime", !!data?.uptime && "uptime", !!data?.sslExpiry && 'sslExpiry']}>
          <MenuItemOption value="responseTime" onClick={() => mutate({ filters: { ...(data || {}), responseTime: !data?.responseTime } })}>Response Time</MenuItemOption>
           <MenuItemOption value="uptime" onClick={() => mutate({ filters: { ...(data || {}), uptime: !data?.uptime } })}>Uptime</MenuItemOption>
          <MenuItemOption value="sslExpiry" onClick={() => mutate({ filters: { ...(data || {}), sslExpiry: !data?.sslExpiry } })}>SSL expiry</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Portal>
  </Menu>);
};

export default MonitorTableMenu;