import React, { useState, useEffect } from 'react';
import {
    ProtectedMonitorDetailsResponse
  } from '@um/uptime-monitoring-shared';
import { useMonitorTableMenuFiltersQuery } from '../query/monitorTableMenuQueries';

type MonitorsSortBy = "response-time" | "uptime" | "ssl-expiry";
/*
  useMonitorSort 
  Sorts monitors based on the column name when clicked.
  Available column to sort
  1. Response Time
  2. Uptime
  3. SSL Expiry

  Whenever sortBy is updated, we have to sort the monitors based on the updated sortBy.

*/
const useMonitorsSort = (monitors:  ProtectedMonitorDetailsResponse[] = []) => {
    const [filteredAndSortedMonitors, setFilteredAndSortedMonitors] = useState<any>([]);
    const [isReverse, setIsReverse] = useState<boolean>(false);
    const { data: filterData } = useMonitorTableMenuFiltersQuery('');
    const [sortBy, setSortBy] = useState('');

    useEffect(() => {
        if(monitors.length){
            const filteredMonitors = filterBasedOnType(monitors);
            const sortedMonitors = sortMonitors(filteredMonitors);
            const reversedMonitors = reverseMonitors(sortedMonitors);

            setFilteredAndSortedMonitors(reversedMonitors);
        }
    }, [monitors, sortBy, filterData, isReverse]);

    const filterBasedOnType = (updatedMonitors) => {
        updatedMonitors = updatedMonitors.filter((monitor) => {
            if( Object(filterData).hasOwnProperty(monitor?.transport) && filterData[monitor?.transport]){
                return monitor;
            }
        });
        return [...updatedMonitors];
    }

    const sortOrderReverse = () => setIsReverse(curr => !curr);

    const reverseMonitors = (updatedMonitors) => {
        if(isReverse)
            updatedMonitors.reverse();
        return updatedMonitors;
    }

    const sortMonitors = (updatedMonitors) => {
        if(sortBy && updatedMonitors.length){
            if(sortBy === 'ssl-expiry'){
                updatedMonitors.sort(sortBySSLExpiry);
            }
        }
        return [...updatedMonitors];
    }

    const sortBySSLExpiry = (a,b) => new Date(a.certificateMonitorMetadata?.certificateExpiryDate).getTime() - new Date(b.certificateMonitorMetadata?.certificateExpiryDate).getTime();

    const updateSortBy = (newSortOrder) => {
        setSortBy(newSortOrder);
    }

    return [
        filteredAndSortedMonitors,
        sortOrderReverse,
        updateSortBy,
        sortBy
    ]

}

export default useMonitorsSort;