import Select from 'react-select';
import useFeatureInformation from '../../hooks/useFeatureInformation';
import { PlanFeature } from '@um/uptime-monitoring-shared';
import { useMemo } from 'react';

const frequencyOptions = [
  { label: '1 minute', value: 1 },
  { label: '5 minutes', value: 5 },
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '1 hour', value: 60 },
  { label: '6 hours', value: 360 },
  { label: '12 hours', value: 720 },
  { label: '24 hours', value: 1440 },
];

const CheckFrequencySelect = ({ value, ...rest }) => {
  console.log(value,rest)
  const featureInformation = useFeatureInformation(PlanFeature.ONE_MINUTE_CHECK_INTERVAL);
  const options = useMemo(() => frequencyOptions.filter((item) => !featureInformation.featureMissing || item.value >30), [featureInformation]);
  const selectedOption = frequencyOptions.find((option) => option.value === value);

  return <Select isSearchable={false} options={options} value={selectedOption} {...rest} />;
};

export default CheckFrequencySelect;
