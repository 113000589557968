import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import queryKeys from './queryKeys';

export const useMonitorTableMenuFiltersQuery = (name: string) => {
  return useQuery(
    queryKeys.monitorTableMenu(name),
    () => {
      const key = `monitor-table-menu-filters-${name}`;
      const filtersValue = window.localStorage.getItem(key);

      if (!filtersValue) {
        return null;
      }

      return JSON.parse(filtersValue) as { [key: string]: boolean };
    },
    {
      keepPreviousData: true,
      refetchOnMount: true,
    }
  );
};

export const useMonitorTableMenuFiltersMutation = (name: string) => {
  const queryClient = useQueryClient();
  const key = `monitor-table-menu-filters-${name}`;

  return useMutation<any, any, { filters: { [key: string]: boolean } }>(
    async ({ filters }) => {
      window.localStorage.setItem(key, JSON.stringify(filters));
    },
    { onSuccess: () => queryClient.invalidateQueries(queryKeys.monitorTableMenu(name)) }
  );
};
